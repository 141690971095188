import '../App.css';
import { useState, useEffect } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { useAuth } from '../AuthContext';

function Clientes() {
  const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  };

  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [direccion, setDireccion] = useState('');
  const [numerodetelefono, setNumerodetelefono] = useState('');
  const [editar, setEditar] = useState(false);
  const [idcliente, setIdcliente] = useState('');
  const [clientesList, setClientes] = useState([]);
  const [resetKey, setResetKey] = useState(0);
  const { currentUser } = useAuth();
  const [filtroNombre, setFiltroNombre] = useState('');
  const [filtroApellido, setFiltroApellido] = useState('');
  const [filtroBalanceCero, setFiltroBalanceCero] = useState(false);

  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout()
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        console.error('Error al cerrar sesión', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No se pudo cerrar sesión correctamente.',
        });
      });
  };

  const aplicarFiltros = () => {
    Axios.post('https://psqlback.onrender.com/clientes/filtrar', {
      nombre: filtroNombre,
      apellido: filtroApellido,
      balanceCero: filtroBalanceCero,
    })
      .then((response) => {
        setClientes(response.data);
      })
      .catch((error) => {
        console.error('Error al aplicar filtros:', error);
        Swal.fire('Error', 'No se pudieron aplicar los filtros.', 'error');
      });
  };

  function verDetallesCliente(idcliente) {
    Axios.get(`https://psqlback.onrender.com/clientes/detalles/${idcliente}`)
      .then((response) => {
        const { cliente, ventas } = response.data;
        let detallesVentas = ventas
          .map(
            (venta) =>
              `<p><b>Modelo:</b> ${venta.modelo}, <b>Color:</b> ${venta.color}, <b>Memoria:</b> ${venta.memoria}, <b>Fecha:</b> ${venta.fecha}, <b>Balance:</b> $${-venta.balance}</p>`
          )
          .join('');

        let totalBalance = ventas.reduce(
          (acc, venta) => acc + venta.balance,
          0
        );

        Swal.fire({
          title: `<strong>Detalles del Cliente: ${cliente.nombre} ${cliente.apellido}</strong>`,
          html: `
            <h3><strong>Ventas Realizadas</strong></h3>
            ${detallesVentas}
            <h3><strong>Total Balance</strong></h3>
            <p>$${-totalBalance}</p>
          `,
          showCloseButton: true,
          focusConfirm: false,
          confirmButtonText: 'Cerrar',
        });
      })
      .catch((error) => {
        console.error('Error al obtener detalles del cliente:', error);
        Swal.fire('Error', 'No se pudo obtener detalles del cliente.', 'error');
      });
  }

  const add = () => {
    Axios.post('https://psqlback.onrender.com/clientes/create', {
      nombre,
      apellido,
      direccion,
      numerodetelefono,
    })
      .then((response) => {
        resetForm();
        getClientes();
        Swal.fire({
          title: 'Registro exitoso',
          text: 'El cliente fue registrado con éxito',
          icon: 'success',
          timer: 3000,
        });
      })
      .catch((error) => {
        Swal.fire('Error', 'No se pudo registrar el cliente.', 'error');
      });
  };
  const update = () => {
    Axios.put('https://psqlback.onrender.com/clientes/update', {
      idcliente: idcliente,
      nombre: nombre,
      apellido: apellido,
      direccion: direccion,
      numerodetelefono: numerodetelefono,
    })
      .then((response) => {
        console.log(response);
        getClientes();
        setEditar(false);
        resetForm();
        Swal.fire({
          title: 'Actualización exitosa',
          text: 'El cliente fue actualizado con éxito',
          icon: 'success',
          timer: 3000,
        });
      })
      .catch((error) => {
        console.error('Error al actualizar:', error);
        Swal.fire('Error', 'No se pudo actualizar el cliente.', 'error');
      });
  };

  const deleteClient = (idcliente) => {
    Swal.fire({
      title: '¿Confirmar eliminación?',
      text: '¿Estás seguro de que quieres eliminar este cliente?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(
          `https://psqlback.onrender.com/clientes/delete/${idcliente}`
        )

          .then((response) => {
            getClientes();
            resetForm();
            Swal.fire(
              'Eliminado',
              'El cliente ha sido eliminado exitosamente.',
              'success'
            );
          })
          .catch((error) => {
            console.error('Error al eliminar:', error);
            Swal.fire('Error', 'No se pudo eliminar el cliente.', 'error');
          });
      }
    });
  };
  const resetForm = () => {
    setNombre('');
    setApellido('');
    setDireccion('');
    setNumerodetelefono('');
    setEditar(false);
    setResetKey((prevKey) => prevKey + 1); // Incrementar la clave de reset para los Selects
  };

  const editarClientes = (val) => {
    window.scrollTo(0, 0);
    setEditar(true);
    setIdcliente(val.idcliente);
    setNombre(val.nombre ? val.nombre.toString() : '');
    setApellido(val.apellido ? val.apellido.toString() : '');
    setDireccion(val.direccion ? val.direccion.toString() : '');
    setNumerodetelefono(
      val.numerodetelefono ? val.numerodetelefono.toString() : ''
    );
  };

  const getClientes = () => {
    console.log(process.env.URL);
    Axios.get('https://psqlback.onrender.com/clientes').then((response) => {
      setClientes(response.data);
    });
  };

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    } else {
      getClientes();
    }
  }, [currentUser, navigate]);

  return (
    <div className="container">
      <button onClick={handleLogout} className="btn btn-secondary">
        Cerrar Sesión
      </button>
      <div className="card text-center">
        <div className="card-header">GESTION DE CLIENTES</div>

        <div className="card-body">
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Nombre:
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Ingrese nombre del cliente"
              value={nombre}
              aria-describedby="basic-addon1"
              onChange={(event) => {
                setNombre(event.target.value);
              }}
            />
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Apellido:
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Ingrese apellido del cliente"
              value={apellido}
              aria-describedby="basic-addon1"
              onChange={(event) => {
                setApellido(event.target.value);
              }}
            />
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Direccion:
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Ingrese dirección del cliente"
              value={direccion}
              aria-describedby="basic-addon1"
              onChange={(event) => {
                setDireccion(event.target.value);
              }}
            />
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Telefono:
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Ingrese el número de telefono del cliente"
              value={numerodetelefono}
              aria-describedby="basic-addon1"
              onChange={(event) => {
                setNumerodetelefono(event.target.value);
              }}
            />
          </div>
        </div>
        <div className="card-footer text-body-secondary">
          {editar ? (
            <div>
              <button className="btn btn-warning m-2" onClick={update}>
                Actualizar
              </button>
              <button className="btn btn-info m-2" onClick={resetForm}>
                Cancelar
              </button>
            </div>
          ) : (
            <button className="btn btn-success" onClick={add}>
              Registrar
            </button>
          )}
        </div>
      </div>

      <div className="card text-center">
        <div className="card-header">FILTRO DE PROVEEDORES</div>

        <div className="card-body">
          <div className="form-check mb-3">
            <select
              value={filtroBalanceCero}
              onChange={(e) => setFiltroBalanceCero(e.target.value === 'true')}
              className="form-control"
            >
              <option value="false">Todos</option>
              <option value="true">Balance 0</option>
            </select>
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Apellido:
            </span>

            <input
              className="form-control"
              type="text"
              placeholder="Apellido"
              value={filtroApellido}
              onChange={(e) => setFiltroApellido(e.target.value)}
            />
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Nombre:
            </span>
            <input
              className="form-control"
              type="text"
              placeholder="Nombre"
              value={filtroNombre}
              onChange={(e) => setFiltroNombre(e.target.value)}
            />
          </div>
        </div>
        <div className="card-footer text-body-secondary">
          <button className="btn btn-success" onClick={aplicarFiltros}>
            Aplicar Filtros
          </button>
        </div>
      </div>

      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nombre</th>
            <th scope="col">Apellido</th>
            <th scope="col">Direccion</th>
            <th scope="col">Numero de telefono</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {clientesList.map((val) => {
            return (
              <tr key={val.idcliente}>
                <td>{val.idcliente}</td>
                <td>{val.nombre}</td>
                <td>{val.apellido}</td>
                <td>{val.direccion}</td>
                <td>{val.numerodetelefono}</td>

                <td>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => {
                        editarClientes(val);
                      }}
                    >
                      Editar
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        deleteClient(val.idcliente);
                      }}
                    >
                      Eliminar
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => verDetallesCliente(val.idcliente)}
                    >
                      Ver Más
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
          <tr>
            <td colSpan="3"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Clientes;
