import '../App.css';
import { useState, useEffect } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { useAuth } from '../AuthContext';

function Ventas() {
  const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  };

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    return date.toLocaleDateString('es-AR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const { currentUser } = useAuth();

  // Estado para almacenar los proveedores cargados desde la API
  const [telefonos, setTelefonos] = useState([]);
  const [clientes, setClientes] = useState([]);

  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout()
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        console.error('Error al cerrar sesión', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No se pudo cerrar sesión correctamente.',
        });
      });
  };

  // Estados para los detalles del teléfono
  const [vendido, setVendido] = useState(false);
  const [telefonoId, setTelefonoId] = useState('');

  // Estados para los detalles de la compra
  const [telefono, setTelefono] = useState({ value: '', label: '' });
  const [cliente, setCliente] = useState({ value: '', label: '' });
  const [fecha, setFecha] = useState(getFormattedDate());

  const [precio, setPrecio] = useState('');
  const [cuantopagoelcliente, setCuantopagoelcliente] = useState('');
  const [idVenta, setIdventa] = useState('');

  const [editar, setEditar] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const [ventasList, setVentas] = useState([]);

  const getVentas = () => {
    console.log(process.env.URL);
    Axios.get('https://psqlback.onrender.com/ventas').then((response) => {
      setVentas(response.data);
    });
  };

  const handleTelefonoChange = (selectedOption) => {
    setTelefono(selectedOption ? selectedOption : { value: '', label: '' });
  };
  const handleClienteChange = (selectedOption) => {
    setCliente(selectedOption ? selectedOption : { value: '', label: '' });
  };
  const handleFechaVentaChange = (event) => {
    setFecha(event.target.value);
  };

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    } else {
      getVentas();
    }
  }, [currentUser, navigate]);

  const resetForm = () => {
    setTelefono(null);
    setCliente(null);
    setPrecio('');
    setFecha(getFormattedDate());
    setCuantopagoelcliente('');
    setEditar(false);
    setVendido(false);
    setResetKey((prevKey) => prevKey + 1); // Incrementar la clave de reset para los Selects
  };
  useEffect(() => {
    Axios.get('https://psqlback.onrender.com/telefonosventas')
      .then((response) => {
        const opcionesTelefonos = response.data.map((telefono) => ({
          value: telefono.idtelefono,
          label: telefono.imei,
        }));
        setTelefonos(opcionesTelefonos);
      })
      .catch((error) => {
        console.error('Error al cargar los telefonos:', error);
      });
    Axios.get('https://psqlback.onrender.com/clientes')
      .then((response) => {
        const opcionesClientes = response.data.map((cliente) => ({
          value: cliente.idcliente,
          label: cliente.nombre,
        }));
        setClientes(opcionesClientes);
      })
      .catch((error) => {
        console.error('Error al cargar los Clientes:', error);
      });
  }, []);

  const add = () => {
    Axios.post('https://psqlback.onrender.com/ventas/create', {
      telefono: telefono.value,
      cliente: cliente.value,
      fecha,
      precio,
      cuantopagoelcliente,
    })
      .then((response) => {
        resetForm();
        getVentas();
        Swal.fire({
          title: 'Registro exitoso',
          text: 'La venta fue registrado con éxito',
          icon: 'success',
          timer: 3000,
        });
        Axios.get('https://psqlback.onrender.com/telefonosventas')
          .then((response) => {
            const opcionesTelefonos = response.data.map((telefono) => ({
              value: telefono.idtelefono,
              label: telefono.imei,
            }));
            setTelefonos(opcionesTelefonos);
          })
          .catch((error) => {
            console.error('Error al cargar los telefonos:', error);
          });
      })
      .catch((error) => {
        Swal.fire('Error', 'No se pudo registrar la venta.', 'error');
      });
  };

  const editarVenta = (val) => {
    console.log('Editando venta con valor:', val);

    window.scrollTo(0, 0);
    setEditar(true);
    setIdventa(val.idventa);

    console.log('Editando venta con valor:', val);

    const telefonoParaEditar = telefonos.find((t) => t.label === val.imei); // Usando IMEI temporalmente
    const clienteParaEditar = clientes.find(
      (c) => c.label === val.nombre_cliente
    ); // Usando nombre del cliente

    console.log('telefonoParaEditar encontrado:', telefonoParaEditar);
    console.log('clienteParaEditar encontrado:', clienteParaEditar);

    setTelefono(telefonoParaEditar || null);
    setCliente(clienteParaEditar || null);

    const fechaFormateada = val.fecha
      ? formatISODateStringToYYYYMMDD(val.fecha)
      : '';
    setFecha(fechaFormateada);
    setPrecio(val.precio ? val.precio.toString() : '');
    setCuantopagoelcliente(
      val.cuantopagoelcliente ? val.cuantopagoelcliente.toString() : ''
    );
  };

  const formatISODateStringToYYYYMMDD = (isoDateString) => {
    const date = new Date(isoDateString);
    return date.toISOString().split('T')[0];
  };

  const deleteVenta = (idventa) => {
    Swal.fire({
      title: '¿Confirmar eliminación?',
      text: '¿Estás seguro de que quieres eliminar esta venta?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(`https://psqlback.onrender.com/ventas/delete/${idventa}`)
          .then((response) => {
            getVentas(); // Recarga la lista de ventas
            Swal.fire(
              'Eliminado',
              'La venta ha sido eliminada exitosamente.',
              'success'
            );
          })
          .catch((error) => {
            console.error('Error al eliminar:', error);
            Swal.fire('Error', 'No se pudo eliminar la venta.', 'error');
          });
      }
    });
  };

  const updateVenta = () => {
    Axios.put(`https://psqlback.onrender.com/ventas/update/${idVenta}`, {
      telefono: telefono.value,
      idcliente: cliente.value, // Asegúrate de que esto coincida con el nombre esperado en el servidor
      fecha,
      precio,
      cuantopagoelcliente,
    })
      .then((response) => {
        Swal.fire(
          '¡Actualizado!',
          'La venta ha sido actualizada con éxito.',
          'success'
        );
        getVentas(); // Recargar las ventas actualizadas
        resetForm();
        setEditar(false);
      })
      .catch((error) => {
        console.error('Error al actualizar la venta:', error);
        Swal.fire(
          'Error',
          'No se pudo actualizar la venta. Verifique los detalles.',
          'error'
        );
      });
  };

  return (
    <div className="container">
      <button onClick={handleLogout} className="btn btn-secondary">
        Cerrar Sesión
      </button>
      <div className="card text-center">
        <div className="card-header">REGISTRAR UNA VENTA</div>
        <div className="card-body">
          {!editar && (
            <>
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  Telefono:
                </span>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  name="telefono"
                  value={
                    telefono
                      ? telefonos.find(
                          (option) => option.value === telefono.value
                        )
                      : null
                  }
                  options={telefonos}
                  placeholder="Seleccione un telefono"
                  onChange={handleTelefonoChange}
                />
              </div>
            </>
          )}
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Clientes:
            </span>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              name="clientes"
              value={
                cliente
                  ? clientes.find((option) => option.value === cliente.value)
                  : null
              }
              options={clientes}
              placeholder="Seleccione un cliente"
              onChange={handleClienteChange}
            />
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Fecha de venta:
            </span>
            <input
              type="date"
              className="form-control"
              placeholder="Ingrese fecha de venta"
              value={fecha}
              aria-describedby="basic-addon1"
              onChange={handleFechaVentaChange}
            />
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Precio:
            </span>
            <input
              type="number"
              className="form-control"
              placeholder="Ingrese precio"
              value={precio}
              aria-describedby="basic-addon1"
              onChange={(event) => {
                setPrecio(event.target.value);
              }}
            />
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Cuanto pagó:
            </span>
            <input
              type="number"
              className="form-control"
              placeholder="Ingrese cuanto pagó el cliente"
              value={cuantopagoelcliente}
              aria-describedby="basic-addon1"
              onChange={(event) => {
                setCuantopagoelcliente(event.target.value);
              }}
            />
          </div>
        </div>
        <div className="card-footer text-body-secondary">
          {editar ? (
            <div>
              <button
                className="btn btn-warning m-2"
                onClick={() => updateVenta(telefonoId)}
              >
                Actualizar
              </button>

              <button className="btn btn-info m-2" onClick={resetForm}>
                Cancelar
              </button>
            </div>
          ) : (
            <button className="btn btn-success" onClick={add}>
              Registrar
            </button>
          )}
        </div>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th className="table-cell-centered" scope="col">
              #
            </th>
            <th className="table-cell-centered" scope="col">
              IMEI
            </th>
            <th className="table-cell-centered" scope="col">
              Fecha
            </th>
            <th className="table-cell-centered" scope="col">
              Cliente
            </th>
            <th className="table-cell-centered" scope="col">
              precio
            </th>
            <th className="table-cell-centered" scope="col">
              Cuanto se le pago
            </th>
          </tr>
        </thead>
        <tbody>
          {ventasList.map((val) => {
            return (
              <tr key={val.idventa}>
                <td className="table-cell-centered">{val.idventa}</td>
                <td className="table-cell-centered">{val.imei}</td>
                <td className="table-cell-centered">{formatDate(val.fecha)}</td>
                <td className="table-cell-centered">{`${val.nombre_cliente} ${val.apellido_cliente}`}</td>
                <td className="table-cell-centered">{val.precio}</td>
                <td className="table-cell-centered">
                  {val.cuantopagoelcliente}
                </td>
                <td>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => {
                        editarVenta(val, val.idventa);
                      }}
                    >
                      Editar
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        deleteVenta(val.idventa);
                      }}
                    >
                      Eliminar
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
          <tr>
            <td colSpan="3"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Ventas;
