import '../App.css';
import { useState, useEffect } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { useAuth } from '../AuthContext';

function Compras() {
  const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  };

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    return date.toLocaleDateString('es-AR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const { currentUser } = useAuth();

  // Estado para almacenar los proveedores cargados desde la API
  const [proveedores, setProveedores] = useState([]);

  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout()
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        console.error('Error al cerrar sesión', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No se pudo cerrar sesión correctamente.',
        });
      });
  };

  // Estados para los detalles del teléfono
  const [color, setColor] = useState('');
  const [imei, setImei] = useState('');
  const [modelo, setModelo] = useState('');
  const [memoria, setMemoria] = useState('');
  const [vendido, setVendido] = useState(false);
  const [telefonoId, setTelefonoId] = useState('');

  // Estados para los detalles de la compra
  const [proveedor, setProveedor] = useState({ value: '', label: '' });
  const [fecha, setFecha] = useState(getFormattedDate());

  const [precio, setPrecio] = useState('');
  const [cuantoselepagoalproveedor, setCuantoselepagoalproveedor] =
    useState('');
  const [idCompra, setIdcompra] = useState('');

  const [editar, setEditar] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const [comprasList, setCompras] = useState([]);

  const getCompras = () => {
    console.log(process.env.URL);
    Axios.get('https://psqlback.onrender.com/compras').then((response) => {
      setCompras(response.data);
    });
  };

  const handleModeloChange = (selectedOption) => {
    setModelo(selectedOption ? selectedOption.value : null);
  };
  const handleColorChange = (selectedOption) => {
    setColor(selectedOption ? selectedOption.value : null);
  };
  const handleProveedorChange = (selectedOption) => {
    setProveedor(selectedOption ? selectedOption : { value: '', label: '' });
  };
  const handleMemoriaChange = (selectedOption) => {
    setMemoria(selectedOption ? selectedOption.value : null);
  };

  const handleFechaCompraChange = (event) => {
    setFecha(event.target.value);
  };

  const modelosIphoneOptions = [
    { value: 'iPhone 7', label: 'iPhone 7' },
    { value: 'iPhone 7 Plus', label: 'iPhone 7 Plus' },
    { value: 'iPhone 8', label: 'iPhone 8' },
    { value: 'iPhone 8 Plus', label: 'iPhone 8 Plus' },
    { value: 'iPhone X', label: 'iPhone X' },
    { value: 'iPhone XR', label: 'iPhone XR' },
    { value: 'iPhone XS', label: 'iPhone XS' },
    { value: 'iPhone XS Max', label: 'iPhone XS Max' },
    { value: 'iPhone 11', label: 'iPhone 11' },
    { value: 'iPhone 11 Pro', label: 'iPhone 11 Pro' },
    { value: 'iPhone 11 Pro Max', label: 'iPhone 11 Pro Max' },
    { value: 'iPhone 12', label: 'iPhone 12' },
    { value: 'iPhone 12 Mini', label: 'iPhone 12 Mini' },
    { value: 'iPhone 12 Pro', label: 'iPhone 12 Pro' },
    { value: 'iPhone 12 Pro Max', label: 'iPhone 12 Pro Max' },
    { value: 'iPhone 13', label: 'iPhone 13' },
    { value: 'iPhone 13 Mini', label: 'iPhone 13 Mini' },
    { value: 'iPhone 13 Pro', label: 'iPhone 13 Pro' },
    { value: 'iPhone 13 Pro Max', label: 'iPhone 13 Pro Max' },
    {
      value: 'iPhone SE (2nd generation)',
      label: 'iPhone SE (2nd generation)',
    },
    {
      value: 'iPhone SE (3rd generation)',
      label: 'iPhone SE (3rd generation)',
    },
    { value: 'iPhone 14', label: 'iPhone 14' },
    { value: 'iPhone 14 Plus', label: 'iPhone 14 Plus' },
    { value: 'iPhone 14 Pro', label: 'iPhone 14 Pro' },
    { value: 'iPhone 14 Pro Max', label: 'iPhone 14 Pro Max' },
  ];

  const coloresIphoneOptions = [
    { value: 'Negro', label: 'Negro' },
    { value: 'Blanco', label: 'Blanco' },
    { value: 'Rojo', label: 'Rojo' },
    { value: 'Amarillo', label: 'Amarillo' },
    { value: 'Púrpura', label: 'Púrpura' },
    { value: 'Verde', label: 'Verde' },
    { value: 'Azul', label: 'Azul' },
    { value: 'Rosa', label: 'Rosa' },
    { value: 'Oro', label: 'Oro' },
    { value: 'Plata', label: 'Plata' },
    { value: 'Grafito', label: 'Grafito' },
    { value: 'Azul pacífico', label: 'Azul pacífico' },
    { value: 'Medianoche', label: 'Medianoche' },
    { value: 'Estelar', label: 'Estelar' },
    { value: 'Alpino', label: 'Alpino' },
  ];

  const MemoriaOptions = [
    { value: '128gb', label: '128gb' },
    { value: '256gb', label: '256gb' },
    { value: '512gb', label: '512gb' },
    { value: '1TB', label: '1TB' },
    { value: '2TB', label: '2TB' },
  ];

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    } else {
      getCompras();
    }
  }, [currentUser, navigate]);

  const resetForm = () => {
    setMemoria(null);
    setModelo(null);
    setColor(null);
    setProveedor(null);
    setPrecio('');
    setImei('');
    setFecha(getFormattedDate());
    setCuantoselepagoalproveedor('');
    setEditar(false);
    setVendido(false);
    setResetKey((prevKey) => prevKey + 1); // Incrementar la clave de reset para los Selects
  };
  useEffect(() => {
    Axios.get('https://psqlback.onrender.com/proveedores')
      .then((response) => {
        const opcionesProveedores = response.data.map((proveedor) => ({
          value: proveedor.idproveedor,
          label: proveedor.nombre,
        }));
        setProveedores(opcionesProveedores);
      })
      .catch((error) => {
        console.error('Error al cargar los proveedores:', error);
      });
  }, []);

  const add = () => {
    Axios.post('https://psqlback.onrender.com/telefonos/create', {
      color,
      modelo,
      memoria,
      vendido: false,
      imei,
    })
      .then((response) => {
        const idTelefono = response.data.idtelefono;
        console.log('CHECKAACA', idTelefono);
        // Ahora realizas la llamada a la API para registrar la compra, usando el ID del teléfono
        console.log('Enviando datos de compra:', {
          telefono: idTelefono,
          proveedor,
          fecha,
          precio,
          cuantoselepagoalproveedor,
          imei,
        });
        Axios.post('https://psqlback.onrender.com/compras/create', {
          telefono: idTelefono,
          proveedor: proveedor.value,
          fecha,
          precio,
          cuantoselepagoalproveedor,
          imei,
        })
          .then((responseCompra) => {
            resetForm();
            getCompras();
            Swal.fire(
              'Éxito',
              'La compra ha sido registrada con éxito.',
              'success'
            );
            resetForm();
          })
          .catch((error) => {
            Swal.fire('Error', 'No se pudo registrar la compra.', 'error');
          });
      })
      .catch((error) => {
        Swal.fire('Error', 'No se pudo registrar el teléfono.', 'error');
      });
  };

  const editarCompras = (val, idcompraa) => {
    window.scrollTo(0, 0);
    setEditar(true);
    setIdcompra(idcompraa);
    console.log('siisisi', idcompraa);

    setModelo(val.modelo || '');
    setColor(val.color || '');
    setMemoria(val.memoria || '');

    const proveedorParaEditar = proveedores.find(
      (proveedor) => proveedor.value === val.idproveedor
    );
    console.log('mmm', val.idtelefono); // Asegurándose de que este valor es correcto
    setTelefonoId(val.idtelefono); // Esto establece el estado pero es asíncrono
    console.log('Actualizando compra con telefonoId: ', telefonoId); // Este log puede no reflejar el cambio inmediatamente

    setProveedor(proveedorParaEditar);
    setFecha(val.fecha ? val.fecha.slice(0, 10) : '');
    setPrecio(val.precio ? val.precio.toString() : '');
    setImei(val.imei ? val.imei.toString() : '');
    setCuantoselepagoalproveedor(
      val.cuantoselepagaalproveedor
        ? val.cuantoselepagaalproveedor.toString()
        : ''
    );
  };

  const updateCompra = (telefonoIdActualizado) => {
    const proveedorId = proveedor.value;
    Axios.put('https://psqlback.onrender.com/compras/update', {
      idcompra: idCompra,
      telefono: telefonoIdActualizado,
      proveedor: proveedorId,
      fecha,
      precio,
      cuantoselepagoalproveedor,
      imei,
    })
      .then(() => {
        Swal.fire(
          '¡Actualizado!',
          'La compra ha sido actualizada con éxito.',
          'success'
        );
        getCompras();
        resetForm();
        setEditar(false);
      })
      .catch((error) => {
        console.error('Error al actualizar la compra:', error);
        console.log('FIJARSE', idCompra);
        Swal.fire('Error', 'No se pudo actualizar la compra.', 'error');
      });
  };

  const deleteCompra = (idcompra) => {
    Swal.fire({
      title: '¿Confirmar eliminación?',
      text: '¿Estás seguro de que quieres eliminar este cliente?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(`https://psqlback.onrender.com/compras/delete/${idcompra}`)

          .then((response) => {
            getCompras();
            resetForm();
            Swal.fire(
              'Eliminado',
              'La compra ha sido eliminado exitosamente.',
              'success'
            );
          })
          .catch((error) => {
            console.error('Error al eliminar:', error);
            Swal.fire('Error', 'No se pudo eliminar la compra.', 'error');
          });
      }
    });
  };

  return (
    <div className="container">
      <button onClick={handleLogout} className="btn btn-secondary">
        Cerrar Sesión
      </button>
      <div className="card text-center">
        <div className="card-header">REGISTRAR UNA COMPRA</div>
        <div className="card-body">
          {!editar && (
            <>
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  Imei:
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ingrese el Imei"
                  value={imei}
                  aria-describedby="basic-addon1"
                  onChange={(event) => {
                    setImei(event.target.value);
                  }}
                />
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  Modelo:
                </span>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  name="modelo"
                  value={
                    modelosIphoneOptions.find(
                      (option) => option.value === modelo
                    ) || null
                  }
                  options={modelosIphoneOptions}
                  placeholder="Seleccione un modeo"
                  onChange={handleModeloChange}
                />
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  Color:
                </span>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  name="color"
                  value={
                    coloresIphoneOptions.find(
                      (option) => option.value === color
                    ) || null
                  }
                  options={coloresIphoneOptions}
                  placeholder="Seleccione un color"
                  onChange={handleColorChange}
                />
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  Memoria:
                </span>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  name="memoria"
                  value={
                    MemoriaOptions.find((option) => option.value === memoria) ||
                    null
                  }
                  options={MemoriaOptions}
                  placeholder="Seleccione una memoria"
                  onChange={handleMemoriaChange}
                />
              </div>
            </>
          )}

          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Proveedores:
            </span>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              name="proveedor"
              value={
                proveedor
                  ? proveedores.find(
                      (option) => option.value === proveedor.value
                    )
                  : null
              }
              options={proveedores}
              placeholder="Seleccione un proveedor"
              onChange={handleProveedorChange}
            />
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Fecha de compra:
            </span>
            <input
              type="date"
              className="form-control"
              placeholder="Ingrese fecha de compra"
              value={fecha}
              aria-describedby="basic-addon1"
              onChange={handleFechaCompraChange}
            />
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Precio:
            </span>
            <input
              type="number"
              className="form-control"
              placeholder="Ingrese precio"
              value={precio}
              aria-describedby="basic-addon1"
              onChange={(event) => {
                setPrecio(event.target.value);
              }}
            />
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Cuanto se pagó:
            </span>
            <input
              type="number"
              className="form-control"
              placeholder="Ingrese cuanto se le pago al proveedor"
              value={cuantoselepagoalproveedor}
              aria-describedby="basic-addon1"
              onChange={(event) => {
                setCuantoselepagoalproveedor(event.target.value);
              }}
            />
          </div>
        </div>
        <div className="card-footer text-body-secondary">
          {editar ? (
            <div>
              <button
                className="btn btn-warning m-2"
                onClick={() => updateCompra(telefonoId)}
              >
                Actualizar
              </button>

              <button className="btn btn-info m-2" onClick={resetForm}>
                Cancelar
              </button>
            </div>
          ) : (
            <button className="btn btn-success" onClick={add}>
              Registrar
            </button>
          )}
        </div>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th className="table-cell-centered" scope="col">
              #
            </th>
            <th className="table-cell-centered" scope="col">
              Telefono
            </th>
            <th className="table-cell-centered" scope="col">
              Proveedor
            </th>
            <th className="table-cell-centered" scope="col">
              Fecha
            </th>
            <th className="table-cell-centered" scope="col">
              Precio
            </th>
            <th className="table-cell-centered" scope="col">
              Cuanto se le pago
            </th>
            <th className="table-cell-centered" scope="col">
              Vendido
            </th>
          </tr>
        </thead>
        <tbody>
          {comprasList.map((val) => {
            return (
              <tr key={val.idcompra}>
                <td className="table-cell-centered">{val.idcompra}</td>
                <td className="table-cell-centered">{val.modelo}</td>
                <td className="table-cell-centered">{val.nombre_proveedor}</td>
                <td className="table-cell-centered">{formatDate(val.fecha)}</td>
                <td className="table-cell-centered">{val.precio}</td>
                <td className="table-cell-centered">
                  {val.cuantoselepagaalproveedor}
                </td>
                <td className="table-cell-centered">
                  {val.vendido ? 'Sí' : 'No'}
                </td>

                <td>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => {
                        editarCompras(val, val.idcompra);
                      }}
                    >
                      Editar
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        deleteCompra(val.idcompra);
                      }}
                    >
                      Eliminar
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
          <tr>
            <td colSpan="3"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Compras;
