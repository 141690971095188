import '../App.css';
import { useState, useEffect } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { useAuth } from '../AuthContext';

function HomePage() {
  const [filtroModelo, setFiltroModelo] = useState('');
  const [filtroMemoria, setFiltroMemoria] = useState('');
  const [filtroVendido, setFiltroVendido] = useState('');
  const [filtroProveedor, setFiltroProveedor] = useState('');
  const [filtroCliente, setFiltroCliente] = useState('');
  const [fechaCompraInicio, setFechaCompraInicio] = useState('');
  const [fechaCompraFin, setFechaCompraFin] = useState('');
  const [fechaVentaInicio, setFechaVentaInicio] = useState('');
  const [fechaVentaFin, setFechaVentaFin] = useState('');

  const [modelosOptions, setModelosOptions] = useState([]);
  const [proveedoresOptions, setProveedoresOptions] = useState([]);
  const [clientesOptions, setClientesOptions] = useState([]);
  // Estado para almacenar los proveedores cargados desde la API
  const [proveedores, setProveedores] = useState([]);

  const modelosIphoneOptions = [
    { value: 'iPhone 7', label: 'iPhone 7' },
    { value: 'iPhone 7 Plus', label: 'iPhone 7 Plus' },
    { value: 'iPhone 8', label: 'iPhone 8' },
    { value: 'iPhone 8 Plus', label: 'iPhone 8 Plus' },
    { value: 'iPhone X', label: 'iPhone X' },
    { value: 'iPhone XR', label: 'iPhone XR' },
    { value: 'iPhone XS', label: 'iPhone XS' },
    { value: 'iPhone XS Max', label: 'iPhone XS Max' },
    { value: 'iPhone 11', label: 'iPhone 11' },
    { value: 'iPhone 11 Pro', label: 'iPhone 11 Pro' },
    { value: 'iPhone 11 Pro Max', label: 'iPhone 11 Pro Max' },
    { value: 'iPhone 12', label: 'iPhone 12' },
    { value: 'iPhone 12 Mini', label: 'iPhone 12 Mini' },
    { value: 'iPhone 12 Pro', label: 'iPhone 12 Pro' },
    { value: 'iPhone 12 Pro Max', label: 'iPhone 12 Pro Max' },
    { value: 'iPhone 13', label: 'iPhone 13' },
    { value: 'iPhone 13 Mini', label: 'iPhone 13 Mini' },
    { value: 'iPhone 13 Pro', label: 'iPhone 13 Pro' },
    { value: 'iPhone 13 Pro Max', label: 'iPhone 13 Pro Max' },
    {
      value: 'iPhone SE (2nd generation)',
      label: 'iPhone SE (2nd generation)',
    },
    {
      value: 'iPhone SE (3rd generation)',
      label: 'iPhone SE (3rd generation)',
    },
    { value: 'iPhone 14', label: 'iPhone 14' },
    { value: 'iPhone 14 Plus', label: 'iPhone 14 Plus' },
    { value: 'iPhone 14 Pro', label: 'iPhone 14 Pro' },
    { value: 'iPhone 14 Pro Max', label: 'iPhone 14 Pro Max' },
  ];

  const coloresIphoneOptions = [
    { value: 'Negro', label: 'Negro' },
    { value: 'Blanco', label: 'Blanco' },
    { value: 'Rojo', label: 'Rojo' },
    { value: 'Amarillo', label: 'Amarillo' },
    { value: 'Púrpura', label: 'Púrpura' },
    { value: 'Verde', label: 'Verde' },
    { value: 'Azul', label: 'Azul' },
    { value: 'Rosa', label: 'Rosa' },
    { value: 'Oro', label: 'Oro' },
    { value: 'Plata', label: 'Plata' },
    { value: 'Grafito', label: 'Grafito' },
    { value: 'Azul pacífico', label: 'Azul pacífico' },
    { value: 'Medianoche', label: 'Medianoche' },
    { value: 'Estelar', label: 'Estelar' },
    { value: 'Alpino', label: 'Alpino' },
  ];

  const MemoriaOptions = [
    { value: '128gb', label: '128gb' },
    { value: '256gb', label: '256gb' },
    { value: '512gb', label: '512gb' },
    { value: '1TB', label: '1TB' },
    { value: '2TB', label: '2TB' },
  ];

  const formatDate = (isoDateString) => {
    // Verifica si isoDateString es nula, vacía, o no es una fecha válida
    if (!isoDateString || isNaN(new Date(isoDateString).getTime())) {
      return '-';
    }

    const date = new Date(isoDateString);
    return date.toLocaleDateString('es-AR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const [telefonosList, setTelefonos] = useState([]);

  const { currentUser } = useAuth();

  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout()
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        console.error('Error al cerrar sesión', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No se pudo cerrar sesión correctamente.',
        });
      });
  };

  const getTelefonos = () => {
    console.log(process.env.URL);
    Axios.get('https://psqlback.onrender.com/telefonos/extra').then(
      (response) => {
        setTelefonos(response.data);
      }
    );
  };

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    } else {
      getTelefonos();
      cargarInicial();
    }
  }, [currentUser, navigate]);

  async function cargarInicial() {
    try {
      // Precarga de proveedores
      const proveedoresRespuesta = await Axios.get(
        'https://psqlback.onrender.com/proveedores'
      );
      const proveedores = proveedoresRespuesta.data.map((proveedor) => ({
        value: proveedor.idproveedor,
        label: proveedor.nombre,
      }));

      // Precarga de clientes
      const clientesRespuesta = await Axios.get(
        'https://psqlback.onrender.com/clientes'
      );
      const clientes = clientesRespuesta.data.map((cliente) => ({
        value: cliente.idcliente,
        label: cliente.nombre,
      }));

      setProveedoresOptions(proveedores);
      setClientesOptions(clientes);
    } catch (error) {
      console.error('Error cargando datos iniciales:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error de Carga',
        text: 'No se pudo cargar la información inicial necesaria.',
      });
    }
  }

  const aplicarFiltros = () => {
    Axios.post('https://psqlback.onrender.com/telefonos/filtrar', {
      modelo: filtroModelo,
      memoria: filtroMemoria,
      vendido: filtroVendido,
      proveedor: filtroProveedor,
      cliente: filtroCliente,
      fechaCompraInicio,
      fechaCompraFin,
      fechaVentaInicio,
      fechaVentaFin,
    })
      .then((response) => {
        setTelefonos(response.data);
      })
      .catch((error) => {
        Swal.fire('Error', 'No se pudieron aplicar los filtros.', 'error');
      });
  };
  const handleModeloChange = (selectedOption) => {
    setFiltroModelo(selectedOption ? selectedOption.value : null);
  };
  const handleProveedorChange = (selectedOption) => {
    setFiltroProveedor(selectedOption ? selectedOption.value : null);
  };
  const handleClienteChange = (selectedOption) => {
    setFiltroCliente(selectedOption ? selectedOption.value : null);
  };
  const handleMemoriaChange = (selectedOption) => {
    setFiltroMemoria(selectedOption ? selectedOption.value : null);
  };
  const handleVendidoChange = (e) => {
    const value = e.target.value;
    setFiltroVendido(value);
  };
  const handleFechaCompraChange = (event) => {
    setFechaCompraInicio(event.target.value);
  };

  const handleFechaVentaChange = (event) => {
    setFechaCompraFin(event.target.value);
  };

  return (
    <div className="container">
      <button onClick={handleLogout} className="btn btn-secondary">
        Cerrar Sesión
      </button>

      <div className="card text-center">
        <div className="card-header">FILTRO DE TELEFONOS</div>

        <div className="card-body">
          <div className="form-check mb-3">
            <select
              value={filtroVendido}
              onChange={(e) => setFiltroVendido(e.target.value)}
              className="form-control"
            >
              <option value="">Todos</option>
              <option value="true">Vendidos</option>
              <option value="false">No Vendidos</option>
            </select>
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Modelo:
            </span>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              name="modelo"
              value={
                modelosIphoneOptions.find(
                  (option) => option.value === filtroModelo
                ) || null
              }
              options={modelosIphoneOptions}
              placeholder="Seleccione un modelo"
              onChange={handleModeloChange}
            />
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Proveedores:
            </span>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              name="proveedor"
              value={
                proveedoresOptions.find(
                  (option) => option.value === filtroProveedor
                ) || null
              }
              options={proveedoresOptions}
              placeholder="Seleccione un proveedor"
              onChange={handleProveedorChange}
            />
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Clientes:
            </span>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              name="cliente"
              value={
                clientesOptions.find(
                  (option) => option.value === filtroCliente
                ) || null
              }
              options={clientesOptions}
              placeholder="Seleccione un cliente"
              onChange={handleClienteChange}
            />
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Memoria:
            </span>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              name="memoria"
              value={
                MemoriaOptions.find(
                  (option) => option.value === filtroMemoria
                ) || null
              }
              options={MemoriaOptions}
              placeholder="Seleccione una memoria"
              onChange={handleMemoriaChange}
            />
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text">Fecha de compra desde:</span>
            <input
              type="date"
              className="form-control"
              value={fechaCompraInicio}
              onChange={(e) => setFechaCompraInicio(e.target.value)}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => setFechaCompraInicio('')}
            >
              Reset
            </button>
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text">Fecha de compra hasta:</span>
            <input
              type="date"
              className="form-control"
              value={fechaCompraFin}
              onChange={(e) => setFechaCompraFin(e.target.value)}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => setFechaCompraFin('')}
            >
              Reset
            </button>
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text">Fecha de venta desde:</span>
            <input
              type="date"
              className="form-control"
              value={fechaVentaInicio}
              onChange={(e) => setFechaVentaInicio(e.target.value)}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => setFechaVentaInicio('')}
            >
              Reset
            </button>
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text">Fecha de venta hasta:</span>
            <input
              type="date"
              className="form-control"
              value={fechaVentaFin}
              onChange={(e) => setFechaVentaFin(e.target.value)}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => setFechaVentaFin('')}
            >
              Reset
            </button>
          </div>
        </div>
        <div className="card-footer text-body-secondary">
          <button className="btn btn-success" onClick={aplicarFiltros}>
            Aplicar Filtros
          </button>
        </div>
      </div>

      <table className="table table-striped">
        <thead>
          <tr>
            <th className="table-cell-centered" scope="col">
              #
            </th>
            <th className="table-cell-centered" scope="col">
              Modelo
            </th>
            <th className="table-cell-centered" scope="col">
              Memoria
            </th>
            <th className="table-cell-centered" scope="col">
              Color
            </th>
            <th className="table-cell-centered" scope="col">
              Proveedor
            </th>
            <th className="table-cell-centered" scope="col">
              IMEI
            </th>
            <th className="table-cell-centered" scope="col">
              Vendido
            </th>
            <th className="table-cell-centered" scope="col">
              Precio compra
            </th>
            <th className="table-cell-centered" scope="col">
              Precio venta
            </th>
            <th className="table-cell-centered" scope="col">
              Fecha compra
            </th>
            <th className="table-cell-centered" scope="col">
              Fecha ventas
            </th>
          </tr>
        </thead>
        <tbody>
          {telefonosList.map((val) => {
            return (
              <tr key={val.idtelefono}>
                <td className="table-cell-centered">{val.idtelefono}</td>
                <td className="table-cell-centered">{val.modelo}</td>
                <td className="table-cell-centered">{val.memoria}</td>
                <td className="table-cell-centered">{val.color}</td>
                <td className="table-cell-centered">{val.nombre_proveedor}</td>
                <td className="table-cell-centered">{val.imei}</td>
                <td className="table-cell-centered">
                  {val.vendido ? 'Sí' : 'No'}
                </td>
                <td className="table-cell-centered">{val.precio_compra}</td>
                <td className="table-cell-centered">{val.precio_venta}</td>
                <td className="table-cell-centered">
                  {formatDate(val.fecha_compra)}
                </td>
                <td className="table-cell-centered">
                  {formatDate(val.fecha_venta)}
                </td>
              </tr>
            );
          })}
          <tr>
            <td colSpan="3"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default HomePage;
